import img1 from "../Images/ChooseIcon/img1.png";
import img2 from "../Images/ChooseIcon/img2.png";
import img3 from "../Images/ChooseIcon/img3.png";
import img4 from "../Images/ChooseIcon/img4.png";
import img5 from "../Images/ChooseIcon/img5.png";
import img6 from "../Images/ChooseIcon/img6.png";
import img7 from "../Images/ChooseIcon/img7.png";

export const chooseData = [
  {
    img: img1,
    text: "Fast Mobility",
    subText: "Quis dapibus volutpat condi",
  },
  {
    img: img2,
    text: "Easy Experience",
    subText: "Quis dapibus volutpat condi",
  },
  {
    img: img3,
    text: "Term Loan",
    subText: "Quis dapibus volutpat condi",
  },
  {
    img: img4,
    text: "Safe and protected",
    subText: "Quis dapibus volutpat condi",
  },
  {
    img: img5,
    text: "Wordwide Locations",
    subText: "Quis dapibus volutpat condi",
  },
  {
    img: img5,
    text: "One term fees",
    subText: "Quis dapibus volutpat condi",
  },
  {
    img: img6,
    text: "Merchant Payment",
    subText: "Quis dapibus volutpat condi",
  },
  {
    img: img1,
    text: "Fast Mobility",
    subText: "Quis dapibus volutpat condi",
  },
];
